import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

const CaseStudyTemplate = ({ data: { datoCmsCaseStudy: caseStudy } }) => {
  const [lightbox, setLightbox] = useState({ open: false, i: 0 })

  const LightBox = ({ images }) => {
    return (
      <div className={`modal ${lightbox.open && 'is-active'}`}>
        <div className="modal-background" />
        <div className="modal-content">
          <div className="image is-4by3">
            <Image
              fluid={images[ lightbox.i ].fluid}
              alt={`${caseStudy.title} Case Study Image`}
            />
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setLightbox(v => ({ ...v, open: false }))}
        />
      </div>
    )
  }
  LightBox.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
  }
  return (
    <Layout>
      <HelmetDatoCms seo={caseStudy.seoMetaTags} />
      <section
        className="hero is-large is-page-hero"
        style={{
          backgroundImage: `url(${caseStudy.heroBackgroundImage.fixed.src})`
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-white has-text-weight-normal is-size-2">
              {caseStudy.title}
            </h1>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          {caseStudy.details.map(detail => (
            <div className="container has-text-centered" key={detail.id}>
              <p>
                <strong>Location:</strong> {detail.location},{' '}
                <strong>Duration:</strong> {detail.duration}
              </p>
              <hr />
              <h3 className="subtitle">Background</h3>
              <div className="content">
                {ReactHtmlParser(
                  detail.backgroundNode.childMarkdownRemark.html
                )}
              </div>
              <hr />
              <h3 className="subtitle">Project</h3>
              <div className="content">
                {ReactHtmlParser(detail.projectNode.childMarkdownRemark.html)}
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="container has-text-centered">
        {caseStudy.testimonies.map(testimonial => (
          <section className="hero" key={testimonial.id}>
            <div className="hero-body">
              <div className="container">
                <div className="is-italic">
                  {ReactHtmlParser(
                    testimonial.testimonyNode.childMarkdownRemark.html
                  )}
                </div>
                <br/>
                <p className="has-text-weight-bold">
                  {testimonial.customerName}
                </p>
                <p>
                  <small>{testimonial.customerCompany}</small>
                </p>
                <hr />
              </div>
            </div>
          </section>
        ))}
      </div>
      <br />
      <div className="container">
        <LightBox images={caseStudy.gallery} />
        <div className="columns is-multiline">
          {caseStudy.gallery.map((image, i) => (
            <button
              tabIndex={i}
              key={image.id}
              className="column is-4"
              onClick={() => setLightbox({ open: true, i })}
              onKeyUp={() => setLightbox({ open: true, i })}
              style={{
                background: 'unset',
                border: 'unset',
                cursor: 'pointer'
              }}
            >
              <figure className="image">
                <Image
                  style={{ maxWidth: '100%' }}
                  fixed={image.fixed}
                  alt={`${caseStudy.title} Case Study Image - ${i}`}
                />
              </figure>
            </button>
          ))}
        </div>
      </div>
      <br/>
      <br/>
    </Layout>
  )
}

CaseStudyTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query CaseStudyBySlug($slug: String!) {
    datoCmsCaseStudy(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      heroBackgroundImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            bri: -20
          }
        ) {
          src
        }
      }
      testimonies {
        id
        customerName
        customerCompany
        testimonyNode {
          childMarkdownRemark {
            html
          }
        }
      }
      gallery {
        fixed (
          height: 300
          width: 400
          imgixParams: {
            auto: "compress"
            fit: "crop"
            crop: "entropy"
          }
        ) {
          ...GatsbyDatoCmsFixed
        }
        fluid (
          maxHeight: 1000
          imgixParams: {
            maxH: 1000
            auto: "compress"
            fit: "crop"
            crop: "entropy"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      details {
        id
        location
        duration
        backgroundNode {
          childMarkdownRemark {
            html
          }
        }
        projectNode {
          childMarkdownRemark {
            html
          }
        }
      }
      # Gallery
      # imageGallery {
      #   ... on DatoCms1x2Layout {
      #     id
      #     model {
      #       apiKey
      #     }
      #     leftImage {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 830
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #     rightImages {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 400
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #   }
      #   ... on DatoCms2x1Layout {
      #     id
      #     model {
      #       apiKey
      #     }
      #     rightImage {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 830
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #     leftImages {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 400
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #   }
      #   ... on DatoCms1x1Layout {
      #     id
      #     model {
      #       apiKey
      #     }
      #     leftImage {
      #       alt
      #       fixed(width: 600, height: 800) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #     rightImage {
      #       alt
      #       fixed(width: 600, height: 800) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #   }
      #   ... on DatoCmsFullWidthLayout {
      #     id
      #     model {
      #       apiKey
      #     }
      #     image {
      #       alt
      #       fluid(maxHeight: 800) {
      #         ...GatsbyDatoCmsFluid
      #       }
      #     }
      #   }
      #   ... on DatoCms2x2Layout {
      #     id
      #     model {
      #       apiKey
      #     }
      #     rightImages {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 400
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #     leftImages {
      #       alt
      #       fixed(
      #         width: 600
      #         height: 400
      #         imgixParams: { crop: "edges", fit: "crop" }
      #       ) {
      #         ...GatsbyDatoCmsFixed
      #       }
      #     }
      #   }
      # }
    }
  }
`
